import {mapActions, mapGetters, mapMutations} from "vuex";
import AOS from 'aos';
import 'aos/dist/aos.css';
//sections
export default {
  name: "blog",
  components:{
  },
  watch: {
    '$route'(newVal){
      if(newVal){
        this.fetchPageContent(this.$route.path.split('/').pop())
      }
    }
  },
  data(){
    return{

    }
  },
  created() {
    this.fetchPageContent(this.$route.path.split('/').pop())
  },
  computed:{
    ...mapGetters({
      pageContent: 'pages/page'
    }),
  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
  },
  methods:{
    ...mapActions({
      fetchPageContent: 'pages/GET_PAGE'
    }),
    scroll(){
      window.scrollTo({
        top: 550,
        behavior: "smooth"
      });
    }
  }
}